@import "../../base_scss/base";
@import "ListItem/ListItem";

.List {
  width: 960px;
  margin: auto;

  @include mobile() {
    width: 85%;
    min-width: 200px;
  }

}

.ListTitlePadding {
  @extend .Service;
  width: 40px;
}

.ListTitle {
  font-size: 2em;
  margin-bottom: 20px;
}

.ListTitleHeading {
 @extend .ListItem;
  padding: 70px 0;
  font-style: italic;
}

.Filters {
  display: flex;

  @include mobile() {
    flex-direction: column;
  }
}

.Filter {
  margin-right: 20px;

  @include mobile() {
    margin-bottom: 15px;
  }
}