/* Generated by Font Squirrel (http://www.fontsquirrel.com) on February 19, 2015 */

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-Black-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-Black-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-Black-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-Black-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-Black-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-Black-webfont.svg#ProximaNova') format('svg');
    font-weight: 800;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-Bold-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-Bold-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-Bold-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-Bold-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-Bold-webfont.svg#ProximaNova') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-BoldIt-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-BoldIt-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-BoldIt-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-BoldIt-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-BoldIt-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-BoldIt-webfont.svg#ProximaNova') format('svg');
    font-weight: 600;
    font-style: italic;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-Extrabold-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-Extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-Extrabold-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-Extrabold-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-Extrabold-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-Extrabold-webfont.svg#ProximaNova') format('svg');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-Light-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-Light-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-Light-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-Light-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-Light-webfont.svg#ProximaNova') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-LightItalic-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-LightItalic-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-LightItalic-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-LightItalic-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-LightItalic-webfont.svg#ProximaNova') format('svg');
    font-weight: 300;
    font-style: italic;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-Regular-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-Regular-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-Regular-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-Regular-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-Regular-webfont.svg#ProximaNova') format('svg');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-RegularItalic-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-RegularItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-RegularItalic-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-RegularItalic-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-RegularItalic-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-RegularItalic-webfont.svg#ProximaNova') format('svg');
    font-weight: 400;
    font-style: italic;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-Semibold-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-Semibold-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-Semibold-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-Semibold-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-Semibold-webfont.svg#ProximaNova') format('svg');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaNova';
    src: url('/fonts/ProximaNova/ProximaNova-SemiboldItalic-webfont.eot');
    src: url('/fonts/ProximaNova/ProximaNova-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/ProximaNova/ProximaNova-SemiboldItalic-webfont.woff2') format('woff2'),
         url('/fonts/ProximaNova/ProximaNova-SemiboldItalic-webfont.woff') format('woff'),
         url('/fonts/ProximaNova/ProximaNova-SemiboldItalic-webfont.ttf') format('truetype'),
         url('/fonts/ProximaNova/ProximaNova-SemiboldItalic-webfont.svg#ProximaNova') format('svg');
    font-weight: 500;
    font-style: italic;

}  
