@import "../../base_scss/_colors.scss";
/*
 *  Usage:
 *
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
 *
 */
$spinkit-spinner-margin: 40px auto !default;
$spinkit-size: 60px !default;
$spinkit-spinner-color: $accent !default;

.sk-folding-cube {
  $cubeCount: 4;
  $animationDuration: 2.4s;
  $delayRange: $animationDuration/2;

  margin: $spinkit-spinner-margin;
  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;
  transform: rotateZ(45deg);

  .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);
  }

  .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $spinkit-spinner-color;
    animation: sk-foldCubeAngle $animationDuration infinite linear both;
    transform-origin: 100% 100%;
  }

  // Rotation / angle
  @for $i from 2 through $cubeCount {
    .sk-cube#{$i} {
      transform: scale(1.1) rotateZ(90deg * ($i - 1));
    }
  }

  @for $i from 2 through $cubeCount {
    .sk-cube#{$i}:before {
      animation-delay: $delayRange / $cubeCount * ($i - 1);
    }
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    } 90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
      }
}

