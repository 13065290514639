@import "../../../index";
@import "../../../base_scss/_colors.scss";

img {
  border-radius: 50%;
}

.ListItem {
  border-left: 1px solid $gray;
  padding: 30px 0;

  a {
    text-decoration: none;
    color: $accent;

    &:visited {
      color: $accent;
    }
  }
}

.Headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.25em;
  color: $title;
  line-height: 1.6em;
  font-weight: 300;

  @include mobile() {
    font-size: 1.15em;
  }
}

.Title {
  margin-left: 10px;

  @include mobile() {
    margin-left: 0;
  }
}

.Service {
  height: 40px;
  position: relative;
  left: -20px;
}

.Info {
  padding: 30px 40px 30px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  height: 60px;
}

.Details {
  display: flex;
  flex-direction: row;
  margin-top: 11px;
  margin-left: 50px;
  font-size: .8em;

  @include mobile() {
    flex-direction: column;
    margin-left: 40px;
    font-size: 1em;
  }
}

.Category, .Comments, .Date, .Duration {
  margin-right: 15px;
  margin-bottom: 10px;

  @include mobile() {
    line-height: 1.3em;
  }

}
